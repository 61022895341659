import logo from '../../logo.svg';
import '../../App.css';

function HomeScreen() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>Life Style Design Limited</p>
        <p>即將推出！</p>
      </header>
    </div>
  );
}

export default HomeScreen;
