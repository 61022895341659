import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import HomeScreen from '../screens/HomeScreen';

export default function Routes() {
  return (
    <BrowserRouter>
      <Route path='/' exact component={HomeScreen} />
    </BrowserRouter>
  );
}
